import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Tile from './components/Tile';
import './styles.css';
import ContactForm from './components/ContactForm'; // Import the ContactForm component
import Statistics from './components/Statistics';
import Benefits from './components/Benefits';

// Import your background images
import bgImage1 from './assets/bg-image1.jpg';
import bgImage2 from './assets/bg-image2.jpg';
import bgImage3 from './assets/bg-image3.jpg';
import bgImage4 from './assets/bg-image4.jpg';
import firstBgImage from './assets/home.jpg';

function Home() {
  return (
    <div className="App">
      <Navbar />
      <Tile
        backgroundImage={firstBgImage}
        heading="AI co-workers for healthcare"
        subheading=" Frontier Health is building a 100x output healthcare workforce that leverages the best of humans and machines to deliver unmatched speed & quality of care, at a fraction of the cost."
      />
      <Statistics />
      <Benefits />
      <ContactForm /> 
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
