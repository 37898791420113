import React from 'react';
import './Benefits.css';
import logo from '../assets/logo1.svg';
import staff from '../assets/user.svg';
import productivity from '../assets/gear.svg';
import machine from '../assets/shield.svg';


function Benefits() {
  return (
    <div className="benefits-section" id="benefits-section">
      <h2>Our AI co-workers deliver ROI across staffing groups & increase productivity</h2>
      <div className="benefits-list">
        <div className="benefit">
          <div className="icon">
            <img src={staff} alt="Icon 1" className="benefit-icon" />
          </div>
          <div className="content">
            <div className='benefit-row'> 
            <img src={logo} className="logo"/>
            <p>Staff are happy they <b>save time </b> on repetitive tasks.</p>
            </div>

            <div className='benefit-row'> 
            <img src={logo} className="logo"/>
            <p>Can focus on more complex elements of <b> patient care. </b></p>
            </div>
            
          </div>
        </div>
        <div className="benefit">
          <div className="icon">
            <img src={productivity} alt="Icon 2" className="benefit-icon" />
          </div>
          <div className="content">
          <div className='benefit-row'> 
            <img src={logo} className="logo"/>
            <p>Workforce more <b> resilient </b> to staffing turnover.</p>
            </div>
            <div className='benefit-row'> 
            <img src={logo} className="logo"/>
            <p>Knowledge & skill is <b>retained better </b> in the team via co-workers.</p>
            </div>
          </div>
        </div>
        <div className="benefit">
          <div className="icon">
            <img src={machine} alt="Icon 3" className="benefit-icon" />
          </div>
          <div className="content">
          <div className='benefit-row'> 
            <img src={logo} className="logo"/>
            <p>Co-workers operate 24/7.</p>
            </div>
            <div className='benefit-row'> 
            <img src={logo} className="logo"/>
            <p><b>Increased safe-guarding  </b>against common manual mistakes and errors.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
